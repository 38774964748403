import React from "react";
import {
  CustomColumn,
  CustomRow,
  CustomText,
  FlexBox,
} from "components/components";
import { getTrimedAddress } from "utils/misc";
import { useHistory } from "react-router-dom";
import Copyable from "components/copyable";

const ContestPlayerLeaderboardTile = ({ item }) => {
  const history = useHistory();
  const number = item?.score;
  const roundedNumber = number.toFixed(2); // as string

  return (
    <div
      style={{
        cursor: "pointer",
        borderBottom: "1px solid var(--border-light)",
        padding: "12px 16px",
        backgroundColor: "var(--base)",
      }}
      onClick={(e) => {
        e.stopPropagation();
        history.push(`/user_team/${item?.team_id}`);
      }}
    >
      <CustomRow alignItems="center">
        <FlexBox flex="2">
          <CustomRow>
            <CustomColumn
              alignItems="flex-start"
              justifyContent="center"
              gap="4px"
            >
              <CustomText
                text={item?.creator?.display_name}
                className="bodyMedium"
              />
              <CustomRow gap="4px">
                {item?.winnings && (
                  <CustomText
                    text={`Won $${item?.winnings}`}
                    style={{ fontSize: "11px" }}
                    color="var(--success)"
                  />
                )}
                <CustomText
                  text={getTrimedAddress(item?.creator?.wallet_address)}
                  className="displaySmall"
                  color="var(--text-2)"
                />
                <Copyable content={item?.creator?.wallet_address} />
              </CustomRow>
            </CustomColumn>
          </CustomRow>
        </FlexBox>
        <FlexBox flex="1">
          <CustomText text={roundedNumber} className="bodyMedium" />
        </FlexBox>
        <FlexBox flex="1">
          <CustomRow alignItems="flex-end" justifyContent="flex-end">
            <CustomText text={`#${item?.rank}`} className="bodyMedium" />
          </CustomRow>
        </FlexBox>
      </CustomRow>
    </div>
  );
};

export default ContestPlayerLeaderboardTile;
