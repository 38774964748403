import { CustomRow, CustomText, PaddingComponent } from "components/components";
import { Wallet } from "@phosphor-icons/react";
import { getUserBalance } from "api/user.api";
import { useQuery } from "@tanstack/react-query";
import { abbreviateNumber } from "utils/number_utils";
import Copyable from "components/copyable";
import { useWallets } from "@privy-io/react-auth";

const UserBalanceTile = () => {
  const { wallets } = useWallets();
  const embeddedWallet = wallets[0];
  const { data } = useQuery({
    queryKey: ["user_balance"],
    queryFn: () => getUserBalance(),
    staleTime: 100,
    retry: 3,
  });
  if (!data) return null;
  const formattedData = abbreviateNumber(data, true);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "4px 12px",
        borderRadius: "24px",
        backgroundColor: "var(--elevation-1)",
      }}
    >
      <CustomRow gap="4px" justifyContent="center" alignItems="center">
        <PaddingComponent paddingTop="6px">
          <Wallet size={16} color="var(--text-1)" />
        </PaddingComponent>
        <CustomText text={`${formattedData} USDC`} className="labelMedium" />
        {embeddedWallet?.address && (
          <Copyable content={embeddedWallet?.address} />
        )}
      </CustomRow>
    </div>
  );
};

export default UserBalanceTile;
