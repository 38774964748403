import ChallengeTile from "./challenge_tile";
import {
  CustomColumn,
  CustomText,
  PaddingComponent,
  CustomRow,
  ScrollingContainer,
  ColumnScrolling,
} from "components/components";
import { getChallenges } from "api/challenges.api";
import withLoader from "shared/withLoader";
import EmptyState from "components/empty_state";

const ChallengesPage = ({ queryData }) => {
  const challengesList = queryData || [];
  if (challengesList.length === 0) {
    return (
      <EmptyState
        title="No challenges available right now."
        subtitle="Check back later for more challenges."
      />
    );
  }
  return (
    <ScrollingContainer>
      <ColumnScrolling>
        <CustomColumn padding="20px 0">
          <CustomRow padding="0 16px">
            <CustomText className="titleLarge" text="Upcoming Challenges" />
          </CustomRow>
          <PaddingComponent height="16px" />
          {challengesList.map((challenge, index) => (
            <ChallengeTile key={index} challenge={challenge} />
          ))}
          <PaddingComponent height="120px" />
        </CustomColumn>
      </ColumnScrolling>
    </ScrollingContainer>
  );
};

export default withLoader(ChallengesPage, getChallenges, {
  queryKey: () => ["challenges"],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 2,
});
