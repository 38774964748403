import {
  CursorDiv,
  CustomRow,
  CustomText,
  FlexGrow,
  PaddingComponent,
} from "components/components";
import logo from "assets/logo.png";
import ConnectWalletText from "modules/login/components/connect_wallet_text";
import { AuthContext } from "contexts/auth_context";
import { useContext } from "react";
import UserBalance from "./user_balance";
import { useHistory } from "react-router-dom";

const HomeHeader = () => {
  const history = useHistory();
  const { isUserLoggedIn } = useContext(AuthContext);
  return (
    <div
      style={{
        borderBottom: "0.5px solid var(--border-light)",
        display: "flex",
        padding: "8px 20px",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <FlexGrow>
        <CustomRow alignItems="center">
          <img
            src={logo}
            alt="Gambit"
            style={{
              width: "28px",
              height: "28px",
              marginRight: "8px",
            }}
          />
          <div
            style={{
              borderRadius: "24px",
              width: "fit-content",
            }}
          >
            <CustomText
              text="Gambit"
              fontFamily="Sancreek"
              fontSize="24px"
              fontWeight="500"
              letterSpacing="2"
              lineHeight="34px"
            />
          </div>
        </CustomRow>
      </FlexGrow>
      <CursorDiv
        onClick={() => {
          history.push("/how-it-works");
        }}
      >
        <PaddingComponent padding="0 12px">
          <CustomText
            text="[How it works]"
            className="bodyMedium"
            color="var(--text-1)"
          />
        </PaddingComponent>
      </CursorDiv>
      {!isUserLoggedIn ? <ConnectWalletText /> : <UserBalance />}
    </div>
  );
};

export default HomeHeader;
