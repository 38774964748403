import React, { useContext } from "react";
import AppBar from "components/appbar";
import {
  PaddingComponent,
  ScrollingContainer,
  ColumnScrolling,
  CustomRow,
  FlexGrow,
  CustomText,
} from "components/components";
import { SecondaryStyledButton } from "components/buttons";
import { useHistory, useParams } from "react-router-dom";
import TeamPlayerTile from "./team_player_tile";
import { createTeam } from "api/team.api";
import { GlobalContext } from "contexts/global_context";
import { useQueryClient } from "@tanstack/react-query";

const SelectTeamBoosters = ({
  selectedPlayersList,
  setIsSelectingBoosters,
  proBooster,
  semiBooster,
  miniBooster,
  handleBoosterSelection,
}) => {
  const queryClient = useQueryClient();
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);
  const history = useHistory();
  const { challengeId } = useParams();
  const [isSavingTeam, setIsSavingTeam] = React.useState(false);

  const handleSavingTeam = async () => {
    try {
      if (!proBooster || !semiBooster || !miniBooster) {
        handleErrorSnackbar(null, "Please select all boosters");
        return;
      }
      setIsSavingTeam(true);
      //remove booster from this list
      const normalTokens = selectedPlayersList.filter(
        (player) =>
          player !== proBooster &&
          player !== semiBooster &&
          player !== miniBooster
      );
      const response = await createTeam({
        challengeId: challengeId,
        normalTokens: normalTokens,
        proBoosterToken: proBooster,
        semiBoosterToken: semiBooster,
        miniBoosterToken: miniBooster,
      });
      setIsSavingTeam(false);
      if (response.status === 200) {
        handleSuccessSnackbar("Team saved successfully");
        history.replace(`/challenge_details/${challengeId}/teams`);
        const queryKey = ["challenges", "my_teams", challengeId];
        queryClient.refetchQueries(queryKey);
        return;
      }
      handleErrorSnackbar(null, "Failed to save team. Please try again");
    } catch (e) {
      setIsSavingTeam(false);
      handleErrorSnackbar(e);
    }
  };

  return (
    <ScrollingContainer>
      <AppBar
        title="Select Booster"
        onClick={() => {
          setIsSelectingBoosters(false);
        }}
      />
      <PaddingComponent height="16px" />
      <BoosterHeader />
      <ColumnScrolling>
        {selectedPlayersList.map((tokenDetails, index) => (
          <TeamPlayerTile
            key={index}
            tokenDetails={tokenDetails}
            isBoosterTile={true}
            proBooster={proBooster}
            semiBooster={semiBooster}
            miniBooster={miniBooster}
            handleBoosterSelection={handleBoosterSelection}
          />
        ))}
      </ColumnScrolling>
      <div
        style={{
          padding: "16px",
        }}
      >
        <SecondaryStyledButton
          isLoading={isSavingTeam}
          onClick={(e) => {
            e.stopPropagation();
            handleSavingTeam();
          }}
        >
          Save Team
        </SecondaryStyledButton>
      </div>
    </ScrollingContainer>
  );
};

const BoosterHeader = () => {
  return (
    <CustomRow padding="6px 12px 16px 12px">
      <FlexGrow>
        <CustomRow alignItems="flex-start">
          <CustomText
            className="bodySmall"
            text="Select Booster"
            color="var(--text-2)"
          />
        </CustomRow>
      </FlexGrow>
      <CustomText text="Pro" className="labelSmall" color="var(--text-3)" />
      <PaddingComponent width="20px" />
      <CustomText text="Semi" className="labelSmall" color="var(--text-3)" />
      <PaddingComponent width="20px" />
      <CustomText text="Mini" className="labelSmall" color="var(--text-3)" />
    </CustomRow>
  );
};

export default SelectTeamBoosters;
