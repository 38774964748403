import React from "react";
import styled, { css } from "styled-components";
import { AuthContext } from "contexts/auth_context";
import ConnectWalletDialog from "components/connect_wallet_dialog";
import { SpinnerComponent } from "components/components";
import { usePrivy } from "@privy-io/react-auth";
import { useEffect, useState } from "react";

export const PrimaryStyle = styled.button`
  ${(props) => css`
    border-radius: 32px;
    padding: ${props.padding || "0 16px"};
    background-color: ${props.color || "var(--primary-color)"};
    font-size: 16px;
    color: ${props.textColor || "#000"};
    border: none;
    height: ${props.height || "48px"};
    width: ${props.width || "100%"};
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: var(--primary-color);
      opacity: 0.8;
      color: #000;
    }

    ${props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
      &:hover {
        opacity: 0.6;
        color: ${props.textColor || "#000"};
      }
    `}
  `}
`;

// Button component
export const PrimaryStyledButton = ({
  onClick,
  children,
  isLoginRequired = false,
  ...props
}) => {
  const { isUserLoggedIn,isUserAuthenticated } = React.useContext(AuthContext);
  const { ready, login, user } = usePrivy();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(ready);
  }, [ready]);

  useEffect(() => {
    if (user) {
      isUserAuthenticated({
        isNavigating: false,
      });
    }
  }, [user, isUserAuthenticated]);

  const handleLogin = async () => {
    try {
      if (user) {
      }
      login();
    } catch (e) {
      console.error(e);
    }
  };


  const handleClick = (e) => {
    if (props.disabled) return;
    if (isLoginRequired && !isUserLoggedIn) {
      handleLogin();
      return;
    }
    onClick?.(e);
  };

  return (
    <PrimaryStyle onClick={handleClick} {...props}>
      {children}
    </PrimaryStyle>
  );
};

const SecondaryStyle = styled.button`
  ${(props) => `
    border-radius: 32px;
    padding: ${props.padding || "0 16px"};
    background-color: ${props.color || "var(--secondary-color)"};
    font-size: 16px;
    color: ${props.textColor || "white"};;
    border: none;
    height:${props.height || "48px"};
    width: ${props.width || "100%"};
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: var(--secondary-color);
      opacity: 0.8;
      color: white;
    }
  `}
`;

// Button component
export const SecondaryStyledButton = ({
  onClick,
  children,
  isLoading,
  ...props
}) => {
  return (
    <SecondaryStyle onClick={onClick} {...props}>
      {isLoading ? <SpinnerComponent size={24} color="white" /> : children}
    </SecondaryStyle>
  );
};

// Button component
export const PrimarySmallButton = ({ onClick, children, ...props }) => {
  return (
    <PrimaryStyledButton
      width="80px"
      height="32px"
      color="var(--primary-color)"
      textColor="#000"
      padding="0 12px"
      onClick={onClick}
      {...props}
    >
      {children}
    </PrimaryStyledButton>
  );
};
