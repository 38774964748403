import withLoader from "shared/withLoader";
import { getUserTeamDetails } from "api/team.api";
import React from "react";
import AppBar from "components/appbar";
import {
  PaddingComponent,
  ScrollingContainer,
  ColumnScrolling,
  CustomRow,
  CustomText,
  FlexGrow,
  InfoBox,
} from "components/components";
import TeamTokenDetailsTile from "./team_token_details_tile";
import { useEffect } from "react";

const UserTeamDetails = ({ queryData, refetch }) => {
  const tokensList = queryData.units;

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 30000);

    return () => clearInterval(intervalId);
  }, [refetch]);

  return (
    <ScrollingContainer>
      <AppBar title="Team Details" />
      <PaddingComponent height="16px" />
      <ColumnScrolling>
        <TotalScore data={queryData} />
        <TeamTokenDetailsHeader />
        {tokensList.map((tokenDetails, index) => (
          <TeamTokenDetailsTile key={index} tokenDetails={tokenDetails} />
        ))}
      </ColumnScrolling>
    </ScrollingContainer>
  );
};

const TotalScore = ({ data }) => {
  return (
    <PaddingComponent padding="12px 16px">
      <InfoBox>
        <CustomRow alignItems="center">
          <CustomText
            text="Total Score"
            className="labelSmall"
            color="var(--text-3)"
          />
          <FlexGrow></FlexGrow>
          <CustomText
            text={data?.total_score?.toFixed(2) ?? "-"}
            className="titleLarge"
          />
        </CustomRow>
      </InfoBox>
    </PaddingComponent>
  );
};

const TeamTokenDetailsHeader = ({ tokenDetails }) => {
  return (
    <CustomRow>
      <PaddingComponent width="16px" />
      <CustomText text="Tokens" className="labelSmall" color="var(--text-3)" />
      <FlexGrow></FlexGrow>
      <CustomText text="Score" className="labelSmall" color="var(--text-3)" />
      <PaddingComponent width="16px" />
    </CustomRow>
  );
};

export default withLoader(UserTeamDetails, getUserTeamDetails, {
  queryKey: ({ teamId }) => ["user-teams", teamId],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 1,
});
