import React , {useContext} from "react";
import { CustomText, CustomRow,PaddingComponent } from "components/components";
import { useHistory } from "react-router-dom";
import ConnectWalletText from "modules/login/components/connect_wallet_text";
import { AuthContext } from "contexts/auth_context";

const CreateTeamButton = ({ challengeId }) => {
  const { isUserLoggedIn } = useContext(AuthContext);
  const history = useHistory();
  if (!isUserLoggedIn) {
   return <PaddingComponent padding = "0 12px 0 0"> <ConnectWalletText /></PaddingComponent>
  }
  return (
    <div
      style={{
        cursor: "pointer",
        paddingRight: "12px",
      }}
      onClick={(e) => {
        e.stopPropagation();
        history.push(`/create_team/${challengeId}`);
      }}
    >
      <CustomRow>
          <CustomText
          text="+ Create Team"
          className="bodyMedium"
          fontWeight="bold"
          color="var(--primary-color)"
        /> 
      </CustomRow>
    </div>
  );
};

export default CreateTeamButton;
