import { usePrivy, useWallets } from "@privy-io/react-auth";
import { useEffect, useState, useContext } from "react";
import Web3 from "web3";
import { saveSessionDetails, tsNow } from "utils/auth_utils";
import { AuthContext } from "contexts/auth_context";
import { CursorDiv, CustomText } from "components/components";

const ConnectWalletText = () => {
  const [, setIsReady] = useState(false);
  const { ready, login,  user} = usePrivy();
  const { isUserAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    setIsReady(ready);
  }, [ready]);

  const handleLogin = async () => {
    try {
      await login();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (user) {
      isUserAuthenticated({
        isNavigating: false,
      });
    }
  }, [user, isUserAuthenticated]);

  return (
    <CursorDiv>
      <CustomText
        text="Connect Wallet"
        onClick={handleLogin}
        className="bodySmall"
        color="var(--primary-color)"
      />
    </CursorDiv>
  );
};

export default ConnectWalletText;
