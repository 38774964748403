import {
  ScrollingContainer,
  ColumnScrolling,
  PaddingComponent,
} from "components/components";
import AppBar from "components/appbar";
import HowItWorksTile from "./how_it_works_tile";
import {
  NumberCircleOne,
  NumberCircleTwo,
  NumberCircleThree,
  NumberCircleFour,
  NumberCircleFive,
  NumberCircleSix,
} from "@phosphor-icons/react";

const HowItWorks = () => {
  return (
    <ScrollingContainer>
      <AppBar title="How it works" />
      <ColumnScrolling>
        <HowItWorksTile
          title="Choose Challenge to participate"
          description={
            "We do host multiple challenges everyday for you to participate. User can choose any challenge to participate."
          }
          Icon={NumberCircleOne}
        />

        <HowItWorksTile
          title="Create Team"
          description={
            "You can create multiple teams for a challenge with boosters for your tokens. Pro booster gives 3x the points for that particular token. Choose wisely."
          }
          Icon={NumberCircleTwo}
        />
        <HowItWorksTile
          title="Fund your wallet"
          description={
            "Fund your embedded wallet with minimum amount to join a contest required. You can join multiple contests with multiple teams"
          }
          Icon={NumberCircleThree}
        />
        <HowItWorksTile
          title="Join contest"
          description={
            "Once after creating a team, join contest once you are more confident"
          }
          Icon={NumberCircleFour}
        />
        <HowItWorksTile
          title="Track your score"
          description={
            "Once contest starts, track your score in real time. You wouldn't want to miss the leaderboard."
          }
          Icon={NumberCircleFive}
        />
        <HowItWorksTile
          title="Winners"
          description={
            "Once challenge finishes, winners will get there rewards in their wallet within few minutes. You can withdraw it anytime."
          }
          Icon={NumberCircleSix}
        />
        <PaddingComponent height="20px" />
      </ColumnScrolling>
    </ScrollingContainer>
  );
};

export default HowItWorks;
