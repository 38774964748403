import {
  CursorDiv,
  CustomColumn,
  CustomRow,
  CustomText,
  Divider,
  FlexGrow,
  SpinnerComponent,
} from "components/components";
import {
  Wallet,
  CaretRight,
  XLogo,
  HandDeposit,
  SignOut,
  TelegramLogo,
  QuestionMark,
  Export,
} from "@phosphor-icons/react";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "contexts/auth_context";
import LoginCheckWrapper from "modules/login/login_check_wrapper";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import { getTrimedAddress } from "utils/misc";
import Copyable from "components/copyable";

const UserProfilePage = () => {
  const { logout } = useContext(AuthContext);
  const { exportWallet } = usePrivy();
  const { wallets } = useWallets();
  const [isLoggingOut, setIsLoggingOut] = React.useState(false);
  const embeddedWallet = wallets[0];

  const history = useHistory();
  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <LoginCheckWrapper>
        <ProfileSettingTile Icon={Wallet} title="Address">
          <AddressConnected wallet={embeddedWallet} />
        </ProfileSettingTile>
        <ProfileSettingTile
          Icon={HandDeposit}
          title="Deposit"
          onClick={() => {
            history.push("/deposit");
          }}
        />
        {/* <ProfileSettingTile
          Icon={Ranking}
          title="Global Leaderboard"
          onClick={() => {
            history.push("/leaderboard");
          }}
        /> */}
        <ProfileSettingTile
          Icon={QuestionMark}
          title="FAQs"
          onClick={() => {
            history.push("/faq");
          }}
        />
        {/* <ProfileSettingTile
          Icon={Trophy}
          title="Airdrop"
          onClick={() => {
            history.push("/airdrop");
          }}
        /> */}
        <ProfileSettingTile
          Icon={Export}
          title="Export Wallet"
          onClick={() => {
            exportWallet();
          }}
        />
        <ProfileSettingTile
          Icon={XLogo}
          title="Tweet about us"
          onClick={() => {
            window.open(
              `https://twitter.com/intent/tweet?text=${urlText}`,
              "_blank"
            );
          }}
        />
        <ProfileSettingTile
          Icon={XLogo}
          title="Follow us"
          onClick={() => {
            const followLink =
              "https://twitter.com/intent/follow?screen_name=Gambit1023711";
            window.open(followLink, "_blank");
          }}
        />
        <ProfileSettingTile
          Icon={TelegramLogo}
          title="Join Telegram"
          onClick={() => {
            const url = "https://t.me/+MaMTbl9pV3tlNDE1";
            window.open(url, "_blank");
          }}
        />

        <ProfileSettingTile
          Icon={SignOut}
          title="Disconnect wallet"
          onClick={async () => {
            if (isLoggingOut) {
              return;
            }
            setIsLoggingOut(true);
            await logout({
              isNavigating: true,
            });
            setIsLoggingOut(false);
          }}
        >
          {isLoggingOut && <SpinnerComponent size={20} color="var(--text-1)" />}
        </ProfileSettingTile>
      </LoginCheckWrapper>
    </div>
  );
};

const tweetContent = `
Just got onboard with @GambitPlayXyz, and it's epic! 🎉🔥
Build your ultimate token squad and compete for amazing prizes. Why you should join:

💥 Craft and create your tokens team
📊 Earn extra points with boosters
🏆 Participate and win dream pool

Calling all crypto lovers!`;

const urlText = encodeURIComponent(tweetContent.replace(/<br\/>/g, "\n"));

const AddressConnected = ({ wallet }) => {
  return (
    <CustomRow gap="8px" justifyContent="center" alignItems="center">
      <div
        style={{
          width: "8px",
          height: "8px",
          borderRadius: "50%",
          backgroundColor: "var(--primary-color)",
        }}
      ></div>
      <CustomText
        text={getTrimedAddress(wallet?.address)}
        className="labelMedium"
        color="var(--text-3)"
      />
      <Copyable content={wallet?.address} />
    </CustomRow>
  );
};

const ProfileSettingTile = ({ Icon, title, children, onClick }) => {
  return (
    <CursorDiv
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
    >
      <CustomColumn gap="4px">
        <CustomRow gap="8px" padding="8px 12px">
          <FlexGrow>
            <CustomRow gap="12px" alignItems="center">
              <Icon size={20} color="var(--text-1)" />
              <CustomText text={title} className="bodyMedium" />
            </CustomRow>
          </FlexGrow>
          {children ? (
            <CustomRow justifyContent="flex-end">{children}</CustomRow>
          ) : (
            <CaretRight size={18} color="var(--text-3)" />
          )}
        </CustomRow>
        <Divider />
      </CustomColumn>
    </CursorDiv>
  );
};

export default UserProfilePage;
