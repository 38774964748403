import {
  CustomColumn,
  CustomRow,
  InfoBox,
  CustomText,
  FlexGrow,
} from "components/components";

const HowItWorksTile = ({ title, description, Icon }) => {
  return (
    <InfoBox
      margin="8px 16px"
      background="var(--elevation-1)"
      onClick={() => {}}
    >
      <CustomRow gap="12px" alignItems="center">
        <Icon size={24} color="var(--primary-color)" />
        <FlexGrow>
          <CustomColumn gap="4px" alignItems="flex-start">
            <CustomText
              className="bodyMedium"
              color="var(--primary-color)"
              text={title}
            />
            <CustomText
              className="labelMedium"
              color="var(--text-2)"
              text={description}
              textAlign="left"
            />
          </CustomColumn>
        </FlexGrow>
      </CustomRow>
    </InfoBox>
  );
};

export default HowItWorksTile;
