import React, { useState } from "react";
import {
  CustomColumn,
  ScrollingContainer,
  ColumnScrolling,
} from "components/components";
import {
  Route,
  Switch,
  useRouteMatch,
  useLocation,
} from "react-router-dom/cjs/react-router-dom";
import AppBar from "components/appbar";
import CreateTeamButton from "./create_team_button";
import { Box } from "@mui/material";
import StickyTabs from "components/sticky_tabs";
import MyContestsPage from "./contest/my_contests_page";
import MyTeamsPage from "./contest/my_teams_page";
import ChallengeContestsPage from "./contest/challenge_contests_page";
import { useParams } from "react-router-dom";
import withLoader from "shared/withLoader";
import { getChallengeDetails } from "api/challenges.api";
import ChallengeTokenDetailsComp from "./challenge_token_details";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { ConnectLoginWrapper } from 'modules/my_challenges/my_challenges_page';

const ChallengeDetailsPage = ({ queryData }) => {
  const location = useLocation();
  const { challengeId } = useParams();
  const history = useHistory();

  // Define routes and tab index mapping
  const tabRoutes = ["contests", "my_contests", "teams"];
  const baseRoute = `/challenge_details/${challengeId}`;
  const routeToIndex = {
    [`${baseRoute}/contests`]: 0,
    [`${baseRoute}/my_contests`]: 1,
    [`${baseRoute}/teams`]: 2,
  };

  // State to keep track of active tab index
  const [value, setValue] = useState(routeToIndex[location.pathname] || 0);

  // Update the tab index when the location changes
  useEffect(() => {
    const newValue = routeToIndex[location.pathname];
    if (newValue !== undefined) {
      setValue(newValue);
    }
  }, [location.pathname, routeToIndex]);

  // Handle tab change
  const handleChange = (event, newValue) => {
    const newRoute = tabRoutes[newValue];
    if (newRoute) {
      history.push(`/challenge_details/${challengeId}/${newRoute}`);
    }
  };

  return (
    <CustomColumn>
      <AppBar title={queryData.title}>
        <CreateTeamButton challengeId={challengeId} />
      </AppBar>
      <ScrollingContainer>
        <ColumnScrolling>
          <ChallengeTokenDetailsComp challengeId={challengeId} />
          <StickyTabs
            value={value}
            handleChange={handleChange}
            tabs={["Contests", "My Contests", "Teams"]}
          />
          <Box
            sx={{
              height: "100vh",
            }}
          >
            <div>
              <Switch>
                <Route path={`${baseRoute}/contests`}>
                  <ChallengeContestsPage
                    challengeId={challengeId}
                    challengeData={queryData}
                  />
                </Route>
                <Route path={`${baseRoute}/my_contests`}>
                <ConnectLoginWrapper subtitle="Please sign in to join contests.">
                    <MyContestsPage challengeId={challengeId} />
                    </ConnectLoginWrapper>
                </Route>
                <Route path={`${baseRoute}/teams`}>
                <ConnectLoginWrapper subtitle="Please sign in to create your teams.">
                    <MyTeamsPage challengeId={challengeId} />
                </ConnectLoginWrapper>
                </Route>
              </Switch>
            </div>
          </Box>
        </ColumnScrolling>
      </ScrollingContainer>
    </CustomColumn>
  );
};

export default withLoader(ChallengeDetailsPage, getChallengeDetails, {
  queryKey: ({ challengeId }) => ["challengeDetails", challengeId],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 1,
});
