import React from "react";
import { Tabs, Tab, AppBar } from "@mui/material";
import styled from "styled-components";

const TabsContainer = styled.div`
  max-width: 1000px;
  width: 100%;
  border-bottom: 1px solid var(--border-light);
`;

const StickyTabs = ({ value, handleChange, tabs }) => {
  return (
    <AppBar
      position="sticky"
      sx={{
        zIndex: 1000,
        backgroundColor: "var(--base)",
      }}
    >
      <TabsContainer>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="var(--primary-color)"
          textColor="var(--primary-color)"
          variant="fullWidth"
          TabIndicatorProps={{
            style: {
              backgroundColor: "var(--primary-color)", // Set the indicator color to red
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              label={tab}
              key={index}
              sx={{
                minWidth: 0, // Ensures equal spacing for tabs
                width: "100%", // Ensures equal spacing for tabs
                textTransform: "none", // Remove uppercase transformation
                fontSize: "16px", // Custom font size
                color:
                  value === index ? "var(--primary-color)" : "var(--text-1)",
                flex: 1, // Ensures equal spacing for tabs
              }}
            />
          ))}
        </Tabs>
      </TabsContainer>
    </AppBar>
  );
};

export default StickyTabs;
