import { usePrivy } from "@privy-io/react-auth";
import { SecondaryStyledButton } from "components/buttons";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "contexts/auth_context";

const ConnectWalletButton = () => {
  const [isReady, setIsReady] = useState(false);
  const { ready, login, user } = usePrivy();
  const { isUserAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    setIsReady(ready);
  }, [ready]);

  useEffect(() => {
    if (user) {
      isUserAuthenticated({
        isNavigating: false,
      });
    }
  }, [user, isUserAuthenticated]);

  const handleLogin = async () => {
    try {
      if (user) {
      }
      login();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "50%",
    }}>
    <SecondaryStyledButton isLoading={!isReady} onClick={handleLogin}>
      Sign In
    </SecondaryStyledButton>
    </div>

  );
};

export default ConnectWalletButton;
