import StickyTabs from "components/sticky_tabs";
import React, { useState ,useContext} from "react";
import MyChallengesList from "./my_challenges_list";
import { MyChallengesType } from "helpers/custom_enums";
import { AuthContext } from "contexts/auth_context";
import EmptyState from "components/empty_state";
import ConnectWalletText from "modules/login/components/connect_wallet_text";

const MyChallengesPage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <StickyTabs
        value={value}
        handleChange={handleChange}
        tabs={["Live", "Upcoming", "Completed"]}
      />
      <div
        style={{
          height: "100%",
        }}
      >
        {value === 0 && (
          <ConnectLoginWrapper subtitle="Please sign in to view your challenges.">
            <MyChallengesList challengeType={MyChallengesType.LIVE} />
            </ConnectLoginWrapper>
        )}
        {value === 1 && (
          <ConnectLoginWrapper subtitle="Please sign in to view your challenges.">
            <MyChallengesList challengeType={MyChallengesType.UPCOMING} />
          </ConnectLoginWrapper>
        )}
        {value === 2 && (
          <ConnectLoginWrapper subtitle="Please sign in to view your challenges.">
            <MyChallengesList challengeType={MyChallengesType.COMPLETED} />
          </ConnectLoginWrapper>
        )}
      </div>
    </div>
  );
};

 export const ConnectLoginWrapper = ({children,subtitle}) => { 
  const { isUserLoggedIn } = useContext(AuthContext);
  if (!isUserLoggedIn) {
    return (
      <EmptyState
        title="Sign In"
        subtitle={subtitle}
      >
        <ConnectWalletText />
      </EmptyState>
    );
  }
  return children;
};

export default MyChallengesPage;
