import { ethers } from "ethers";
import { getDepositDetails } from "api/user.api";

// USDC Contract Address on Base
const chainId = 8453;

// Request data for sendTransaction, adjusted for ERC20 token transfer
export const getTxnData = async (amountInUSD, wallets) => {
  try {
    const depositDetails = await getDepositDetails();
    if (!depositDetails) {
      throw new Error("Failed to fetch deposit details");
    }
    const embeddedWallet = wallets[0];

    if (!embeddedWallet) return;

    const tokenAmount = usdToUsdc(amountInUSD);
    // Construct the data for the transfer method of the ERC-20 contract
    const contractInterface = new ethers.utils.Interface([
      "function transfer(address to, uint256 value) public returns (bool)",
    ]);

    const data = contractInterface.encodeFunctionData("transfer", [
      depositDetails.gambit_team_wallet, // recipient address
      tokenAmount,
    ]);

    return {
      to: depositDetails.token_address,
      chainId: chainId,
      data,
      value: "0x0", // value must be 0 for ERC20 transfers
      // gasLimit: estimatedGasLimit.toHexString(), // Add the estimated gas limit
    };
  } catch (e) {
    if (e.code === "UNPREDICTABLE_GAS_LIMIT") {
      return "Insufficient balance or gas to pay for the transaction";
    }
    return e.reason || e.message || "Transaction failed";
  }
};

// Function to Convert USD to USDC (Assuming 1 USDC = $1)
const usdToUsdc = (amountInUSD) => {
  return ethers.utils.parseUnits(amountInUSD.toString(), 6); // USDC has 6 decimals
};
