import React, { useEffect, useContext } from "react";
import {
  Route,
  Switch,
  useLocation,
  useHistory,
  Redirect,
} from "react-router-dom";
import HomePage from "modules/home/home";
import LoginPage from "modules/login/login";
import { AuthContext } from "contexts/auth_context";
import styled from "styled-components";
import ChallengeDetailsPage from "modules/challenge_details/challenge_details_page";
import CreateTeamPage from "modules/create_team/create_team_page";
import SelectTeamBoosters from "modules/create_team/select_team_boosters";
import ContestPage from "modules/challenge_details/contest/contest_page";
import DepositPage from "modules/user_profile/deposit_page";
import WithdrawalPage from "modules/user_profile/withdrawal_page";
import FaqsPage from "modules/user_profile/faq/faqs_page";
import AirdropPage from "modules/user_profile/airdrop_page";
import SelectContestJoinTeams from "modules/challenge_details/select_contest_join_teams";
import UserTeamDetails from "modules/team/user_team_details";
import PageNotFound from "modules/misc_pages/page_not_found";
import HowItWorks from "modules/howitworks/how_it_works";

const Routes = () => {
  let location = useLocation();
  const { isUserAuthenticated, isUserLoggedIn } = useContext(AuthContext);
  const history = useHistory();

  let background = location.state && location.state.background;

  useEffect(() => {
    isUserAuthenticated({
      isNavigating: false,
    }).then((path) => {
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppWrapper>
      <Switch location={background || location}>
        <Route
          exact
          path="/"
          render={({ match }) => {
              return <Redirect to="/home" />;
          }}
        ></Route>
        <Route exact path="/signin">
          <LoginPage />
        </Route>
        <Route exact path="/signup">
          <LoginPage />
        </Route>
        <Route
          path="/create_team/:challengeId"
          render={({ match }) => {
            return <CreateTeamPage challengeId={match.params.challengeId} />;
          }}
        />
        <Route
          path="/user_team/:teamId"
          render={({ match }) => {
            return <UserTeamDetails teamId={match.params.teamId} />;
          }}
        />
        <Route exact path="/select_team_boosters">
          <SelectTeamBoosters />
        </Route>
        <Route
          exact
          path="/challenge_details/:challengeId"
          render={({ match }) => (
            <Redirect
              to={`/challenge_details/${match.params.challengeId}/contests`}
            />
          )}
        />
        {/* Main Route handling path with activeTab */}
        <Route
          exact
          path="/challenge_details/:challengeId/:activeTab"
          render={({ match }) => (
            <ChallengeDetailsPage
              challengeId={match.params.challengeId}
              activeTab={match.params.activeTab}
            />
          )}
        />
        <Route exact path="/deposit" component={DepositPage} />
        <Route exact path="/withdraw" component={WithdrawalPage} />
        <Route exact path="/faq" component={FaqsPage} />
        <Route exact path="/airdrop" component={AirdropPage} />
        <Route exact path="/how-it-works" component={HowItWorks} />
        {/* <Route exact path="/leaderboard" component={GlobalLeaderboard} /> */}
        <Route
          exact
          path="/challenge/:challengeId/contest_details/:contestId"
          render={({ match }) => {
            return (
              <ContestPage
                contestId={match.params.contestId}
                challengeId={match.params.challengeId}
              />
            );
          }}
        />
        <Route
          exact
          path="/challenge/:challengeId/contest_details/:contestId/select_teams"
          render={({ match }) => {
            return (
              <SelectContestJoinTeams
                contestId={match.params.contestId}
                challengeId={match.params.challengeId}
              />
            );
          }}
        />
        <Route
          exact
          path={["/home", "/my-challenges", "/settings"]}
          component={HomePage}
        />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </AppWrapper>
  );
};

const AppWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  height: 100vh;
  overflow: hidden;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: var(--background);
  padding-bottom: 60px;
`;

export default Routes;
